export default {
  navTab: {
    title: 'Jiayou',
    tab1: 'Inicio',
    tab2: 'QUIÉNES SOMOS',
    tab3: 'TRABAJA CON NOSOTROS',
    tab4: 'CONTÁCTANOS'
  },
  homeTop: {
    h3Title: 'CONTACT CENTER',
    h3Title1: 'OMNICANAL',
    desc: 'Expertos en atención al cliente,soporte técnico y ventas'
  },
  homeBottom: {
    title: 'Qué ofrecemos',
    menu1: 'EFICIENCIA',
    menuDesc1: 'Operamos con un modelo de gestión que implementa un proceso de mejora continua en tu operación y nuestro personal, apoyado en Work Force Management,Capacitación, Business Intelligence y Command Center.',
    menu2: 'PLATAFORMA MULTICANAL',
    menuDesc2: 'Gestionamos las interacciones con tus clientes en una plataforma multicanal que permite contactar de forma adecuada a tus clientes, por los medios que ellos buscan y se ajustan a sus necesidades.',
    menu3: 'PERSONAL EXPERTO',
    menuDesc3: 'Contamos con atracción, reclutamiento y capacitación de personal perfilado, así como un programa de cultura organizacional enfocado al bien-estar de nuestros colaboradores para impulsar su desarrollo profesiona',
    menu4: 'LA MEJOR SOLUCIÓN DE',
    menu4br: 'CONTACTO',
    menuDesc4: 'Analizamos y optimizamos tus procesos para ser más eficientes y ayudar a rentabilizar tu empresa'
  },
  bottom: {
    title: 'Kolong Tech- Call Center',
    email: 'informacion@kolongtech.com',
    facebook: 'Facebook: Kolong Tech- Call Center',
    about: 'QUIÉNES SOMOS',
    joinUs: 'TRABAJA CON NOSOTROS',
    contact: 'CONTÁCTANOS',
    privacyTitle: 'Enlaces rápidos',
    privacy: 'Aviso de Privacidad'
  },
  aboutUs: {
    title: 'Perfil de la empresa',
    p1: 'XACC Call Centers, establecido en 2017,es un proveedor de soluciones de recursos humanos de renombre mundial,con sede en Washington, EE. UU. Actualmente, las sucursales están ubicadas en Johor Bahru, Malasia, Yakartade Indonesia, Singapur, Delhi de India, Beijing de China y México.',
    p2: 'Actualmente, la oficina mexicana se conecta principalmente con las empresas financieras Soform sobre sus operaciones como los recordatorios de reembolso de préstamos bancarios y  de centros de atención al cliente.La Oficina en México cuenta con 100 empleados (se espera a construir una oficina con 500 empleados), y el negocio del mercado mexicano es actualmente la sección de negocios con alto potencial de crecimiento  en la que se enfoca el grupo.',
    p3: 'Con el crecimiento económico rápido en México, tantas las empresas locales como las multinacionales, han logrado el desarrollo a gran velocidad en sus negocios.Además,sus demandas de servicio al cliente se han vuelto cada vez más fuertes. Así los recursos humanos de atención al cliente se han convertido en un bloqueo creativo que restringe la rápidez del desarrollo de las empresas. Estamos comprometidos a proporcionar a los cliente los servicios de alta calidad,como la consultoría de preparación de soporte al cliente,la consultoría de reclutamiento / capacitación / evaluación de persona de soporte al cliente y, servicios de subcontratación del centro de servicio al cliente y,ayudamos a las empresas a resolver los problemas complicados del centro de servicio al cliente.',
    p4: 'Para los empleados',
    p5: 'excelentes,ofrecemos salarios competitivos y la oportunidad de trabajar y estudiar en la sede del Grupo Americano.Respetando plenamente las políticas, las leyes y los costumbres de Mexico,hemos contratado a un bufete profesional para facilitar a construir un sistema legal de operación de la empresa que cumpla con las normas.En el plan de seguimiento, se invitará a abogados especiales a realizar una formación jurídica en cumplimiento empresarial,confidencialidad de datos de la empresa,relaciones laborales, y capacitación de firmar los documentos legales correspondientes.'
  },
  joinUs: {
    title: 'Reclutamiento',
    subTitle: 'Asesor telefónico',
    descTitle: 'Descripción de puesto',
    descSubTitle: '【Asesor telefónico】',
    content1: 'Por expansión IMPORTANTE EMPRESA DEL RAMO FINTECH se en-',
    content2: 'invitamos a unirte a nuestro gran equipo de asesores telefónicos!!',
    section1Title: 'Ofrecemos:',
    section1Desc1: 'Sueldo base $5500',
    section1Desc2: 'Incentivos por productividad desde los $1000',
    section1Desc3: 'Bono de puntualidad $500',
    section1Desc4: 'Bono por referidos $350',
    section1Desc5: 'Prestaciones de ley desde el primer día',
    section1Desc6: 'Capacitación pagada',
    section1Desc7: 'Servicio de Coffe Break gratuito',
    section1Desc8: 'Comedor con máquina de refrescos (Terraza con vista al castillo de Chapultepec)',
    section1Desc9: 'Salas de Juegos',
    section1Desc10: 'Instalaciones modernas',
    section2Title: 'Requisitos:',
    section2Desc1: 'De 18 a 45 años',
    section2Desc2: 'Secundaria concluida',
    section2Desc3: 'Documentación en regla',
    section2Desc4: 'No se necesitas Experiencia ¡¡Nosotros te capacitamos!!',
    section2Desc5: 'Turno de 9 a 6 de lunes a domingo con descanso entre semana',
    vitae: 'Manera de entregar (postular) su Curriculum Vitae:',
    vitaeGray: 'Postulación vía Correo electrónico',
    vitaeOrange: '“reclutamiento@kolongtech.com”'
  },
  contactUs: {
    title: 'Contáctanos',
    p1: 'Direccion de empresa：',
    p2: 'Cto. Interior Melchor Ocampo 469, Anzures, Miguel Hidalgo, 11590 Ciudad',
    p3: 'de México, CDMX',
    p4: 'Correo Electronico：',
    p5: 'informacion@kolongtech.com',
    p6: 'Facebook：',
    p7: 'Kolong Tech- Call Center'
  }
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import zh from './zh'
import en from './en'
import es from './es'
Vue.use(VueI18n)

const messages = {
  en: {
    ...en
  },
  zh_cn: {
    ...zh
  },
  es: {
    ...es
  }
}
const i18n = new VueI18n({
  locale: 'en',
  messages: messages
})
export default i18n

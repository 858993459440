export default {
  navTab: {
    title: 'Jiayou',
    tab1: 'Start',
    tab2: 'WHO WE ARE',
    tab3: 'WORK WITH US',
    tab4: 'CONTACT US'
  },
  homeTop: {
    h3Title: 'OMNICHANNEL CONTACT CENTER',
    h3Title1: 'Human Resource and Call Center Solution',
    desc: 'Experts in customer service, technical support and human resource'
  },
  homeBottom: {
    title: 'What we offer',
    menu1: 'EFFICIENCY',
    menuDesc1: 'We operate with a management model that implements a continuous improvement process in your operation and our staff, supported by Work Force Management, Training, Business Intelligence and the Command Center.',
    menu2: 'PLATAFORMA MULTICANAL',
    menuDesc2: 'Gestionamos las interacciones con tus clientes en una plataforma multicanal que permite contactar de forma adecuada a tus clientes, por los medios que ellos buscan y se ajustan a sus necesidades.We manage interactions with your customers on a multi-channel platform that allows you to contact your customers appropriately, by the means they seek and adjust to their needs.',
    menu3: 'EXPERT STAFF',
    menuDesc3: 'We have the attraction, recruitment and training of qualified personnel, as well as an organizational culture program focused on the well-being of our collaborators to promote their professional development.',
    menu4: 'THE BEST SOLUTION OF',
    menu4br: 'CONTACT',
    menuDesc4: 'We analyze and optimize your processes to be more efficient and help make your company profitable'
  },
  bottom: {
    title: 'Jiayou Outsource',
    email: 'contact@dino-outsource.com',
    facebook: 'Facebook: Jiayou Outsource',
    about: 'ABOUT US',
    joinUs: 'WORK WITH US',
    contact: 'CONTACT US',
    privacyTitle: 'quick links',
    privacy: 'Notice of Privacy'
  },
  aboutUs: {
    title: 'Company Profile',
    p1: 'Jiayou OutSourcing Limited , established in 2022, is an experienced HR solution provider, headquartered in Lagos, Nigeria.We are planing to be the expert in HR & Call center area.Currently, We has 4 branches and 500+ excellect service staffs to broost your business.',
    p2: 'With rapid economic growth in Nigeria, both local companies and multinationals have achieved rapid development in their businesses. In addition, their demands for customer service have become increasingly strong. Thus, human resources for customer service have become a creative block that restricts the speed of business development. We are committed to providing customers with high-quality services, such as customer support readiness consulting, customer support person recruitment/training/assessment consulting, and customer service center outsourcing services, and so on. we help companies solve complicated customer service center problems.',
    p4: 'For employees',
    p5: 'excellent, we offer competitive salaries and the opportunity to work and study at the headquarters of the American Group. Fully respecting the policies, laws and customs of Mexico, we have hired a professional firm to facilitate the construction of a legal operating system of the company that complies with the regulations. In the follow-up plan, special lawyers will be invited to carry out legal training in business compliance, confidentiality of company data, labor relations, and training in signing the corresponding legal documents.'
  },
  joinUs: {
    title: 'Recruitment',
    subTitle: 'Telephone advisor',
    descTitle: 'Job Description',
    descSubTitle: '【Telephone Advisor】',
    content1: 'Due to expansion, an IMPORTANT COMPANY IN THE FINTECH BRANCH is en-',
    content2: 'we invite you to join our great team of telephone advisors!!',
    section1Title: 'We offer:',
    section1Desc1: 'Base salary 50k ~ 55K NGN',
    section1Desc2: 'Incentives for productivity from 20k ~ 100K NGN',
    section1Desc3: 'Punctuality Bonus 5000 NGN',
    section1Desc4: 'Referral Bonus 350 NGN',
    section1Desc5: 'Benefits of law from the first day',
    section1Desc6: 'Paid Training',
    section1Desc7: 'Free Coffee Break Service',
    section1Desc8: 'Dining room with soda machine (Terrace with a view of Chapultepec Castle)',
    section1Desc9: 'Game Rooms',
    section1Desc10: 'Modern Installations',
    section2Title: 'Requirements:',
    section2Desc1: '18 to 45 years old',
    section2Desc2: 'Secondary completed',
    section2Desc3: 'Documentation in order',
    section2Desc4: "No experience needed, we'll train you!!",
    section2Desc5: 'Shift from 9 to 6 from Monday to Sunday with rest during the week',
    vitae: 'How to submit (apply) your Curriculum Vitae:',
    vitaeGray: 'Application via Email',
    vitaeOrange: '“recruitment@dino-outsource.com”'
  },
  contactUs: {
    title: 'CONTACT US',
    p1: 'Company Address：',
    p2: 'Cto. Interior Melchor Ocampo 469, Anzures, Miguel Hidalgo, 11590 Ciudad',
    p3: 'from Mexico, CDMX',
    p4: 'Email:',
    p5: 'contact@dino-outsource.com',
    p6: 'Facebook：',
    p7: 'Jiayou Outsource'
  }
}
